<template>

<!--用户管理 校园管理 班级管理 -->
  <div class="container">
    <div class="header-container">
      <div class="add-container">
        <router-link :to="{name: 'managementSchoolClassAdd', query: {gradeId: gradeId, schoolId: schoolId, gradeName: gradeName}}">
          <el-button class="addUser-button">
            新增
          </el-button>
        </router-link>
        <el-divider direction="vertical"></el-divider>
        <router-link  :to="{name: 'managementSchoolGrade', query: {schoolId: schoolId, schoolName: schoolName }}">
          <el-button class="return-button"
            >返回</el-button>
        </router-link>
      </div>
    </div>
    <div class="body-container">
      <el-table
        :data="AdministratorListSchoolClassData"
        stripe
        style="width: 100%">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          prop="className"
          label="班级名称">
        </el-table-column>
        <!-- <el-table-column
          prop="teacherName"
          label="班主任"
          width="140">
        </el-table-column>
        <el-table-column
          prop="teacherPhone"
          label="手机号码"
          width="140">
        </el-table-column> -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <router-link :to="{name: 'managementSchoolClassEdit',params: {item: scope.row}, query: {gradeId: gradeId, schoolId: schoolId, gradeName: gradeName }}">
              <el-button
                size="mini"
                type="text"
                >编辑</el-button>
            </router-link>
            <el-divider direction="vertical"></el-divider>
            <el-button
              @click="showDel(scope.row.id)"
              size="mini"
              type="text"
              >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer-container">
    </div>
  </div>
</template>
<script>
// import storage from '@/globals/storage/index.js'
import AdministratorService from '@/globals/service/management/Administrator.js'
export default {
  data () {
    return {
      schoolName: null,
      gradeName: null,
      schoolId: null,
      gradeId: null,
      AdministratorListSchoolClassData: [],
      show: {
        startPage: 1,
        pageSize: 8,
        totalPage: 1
      }
    }
  },
  created () {
    this.gradeId = this.$route.query.gradeId
    this.schoolId = this.$route.query.schoolId
    this.gradeName = this.$route.query.gradeName
    this.schoolName = this.$route.query.schoolName
    this.getData()
  },
  methods: {
    del (id) {
      AdministratorService.AdministratorDelNewGradeClass({ list: [id] }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.getData()
      })
    },
    editStartPage (index) {
      this.show.startPage = index
      this.getData()
    },
    showDel (id) {
      this.$confirm('此操作将永久删除该班级, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.del(id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    getData () {
      AdministratorService.AdministratorListSchoolClass({ gradeId: this.gradeId }).then(res => {
        this.AdministratorListSchoolClassData = res.data
      })
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  min-height: 100%;
  background-color: #fff;
  padding: 0 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .header-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 120px;
    .seek-container{
      display: flex;
      align-items: center;
      .seek-section{
        border: 1px solid #34BBEC;
        border-radius: 20px;
        display: flex;
        align-items: center;
        padding: 0 22px;
        color: #333;
        /deep/.el-input__inner{
          border: none !important;
          padding: 0 10px !important;
        }
        .el-icon-search{
          color: #999999;
        }
      }
      .seek-button{
        width: 80px;
        background-color: #34BBEC;
        margin-left: 20px;
        border-radius: 20px;
        color: #fff;
      }
    }
    .add-container{
      .addUser-button{
        background-color: #34BBEC;
        border-radius: 20px;
        color: #fff;
      }
      .return-button{
        background-color: #34BBEC;
        border-radius: 20px;
        color: #fff;
      }
    }
  }
  .body-container{
    flex: 1;
  }
  .footer-container{
    display: flex;
    width: 100%;
    padding: 33px 0;
    .el-button{
      margin-right: 160px;
    }
  }
}
</style>
